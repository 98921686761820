import { throttle } from "@/utils/utils"
import computeScrollIntoView from "compute-scroll-into-view"

let base = {}

base.init = () => {
    base.initMenu()
    base.fixVhUnits()
}

base.initMenu = () => {
    base.handleMenuItemClick()
    base.handleRwdMenu()
    base.handleScroll()
    window.addEventListener("scroll", base.handleScroll)
}

base.handleRwdMenu = () => {
    let $toggle = document.querySelector(".-js-menu-toggle")

    if (!$toggle) {
        return
    }

    $toggle.addEventListener("click", (e) => {
        e.preventDefault()

        if (document.body.classList.contains("-no-scroll")) {
            document.body.classList.remove("-no-scroll", "-show-menu")
        } else {
            document.body.classList.add("-no-scroll", "-show-menu")
        }

        return false
    })
}

base.handleMenuItemClick = () => {
    let $links = document.querySelectorAll("a[href*='#menu-']")
    let linkHandler = function(e) {
        e.preventDefault()

        let target = this.getAttribute("href")
        let $target = document.querySelector(target)
        let $header = document.querySelector(".t-Header")

        if (!$target || !$header) {
            return
        }

        document.body.classList.remove("-no-scroll", "-show-menu")

        let pageHeight = document.body.scrollHeight
        let offset = 100
        let startPercent = (($target.offsetTop - offset) / pageHeight) * 100

        console.log(startPercent, (pageHeight * startPercent) / 100)

        // let big = window.innerHeight < $target.offsetHeight
        // let offset = 50

        // let scroll = computeScrollIntoView($target, {
        //     block: "start",
        // }).pop()

        window.scroll({
            top: (pageHeight * startPercent) / 100,
            left: 0,
            behavior: "smooth",
        })

        return false
    }

    for (let $link of $links) {
        $link.addEventListener("click", linkHandler)
    }
}

base.handleScroll = () => {
    let $sections = [...document.querySelectorAll(".-js-menu-section")]
    let pageHeight = document.body.scrollHeight
    let windowHeight = window.innerHeight
    let totalScroll = pageHeight - windowHeight
    let scrolled = window.pageYOffset || document.documentElement.scrollTop
    let scrolledPercent = (scrolled / totalScroll) * 100
    let offset = 100
    let active = false

    if (scrolledPercent > 100) {
        scrolledPercent = 100
    }

    if (!$sections.length) {
        return
    }

    for (let $section of $sections) {
        let startPercent = (($section.offsetTop - offset) / pageHeight) * 100
        let endPercent = (($section.offsetTop + $section.clientHeight + offset) / pageHeight) * 100

        if (endPercent > 100) {
            endPercent = 100
        }

        if (scrolledPercent >= startPercent && scrolledPercent <= endPercent) {
            active = true
            base.setActiveMenuItem($section.id)
        }
    }

    if (!active) {
        base.setActiveMenuItem($sections.shift().id)
    }

    if (scrolled > 0) {
        document.body.classList.add("-fixed-menu")
    } else {
        document.body.classList.remove("-fixed-menu")
    }
}

base.setActiveMenuItem = (id) => {
    let $section = document.querySelector(`.t-Header__menu_item a[href='#${id}']`)

    if (!$section) {
        return
    }

    base.removeActiveMenuItem()

    $section.parentElement.classList.add("-active")
}

base.removeActiveMenuItem = () => {
    let $active = document.querySelector(".t-Header__menu_item.-active")

    if ($active) {
        $active.classList.remove("-active")
    }
}

base.fixVhUnits = () => {
    let fix = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", throttle(fix, 200))
    fix()
}

export default base
