const component = {}

component.init = () => {
    let $component = document.querySelector(".-js-pages-list")
    let $items = document.querySelectorAll(".-js-pages-list-item")

    if (!$component || !$items || $items.length < 2) {
        return
    }

    $component.classList.add("-js-upgraded")

    let removeActive = function() {
        for (const $item of $items) {
            $item.classList.remove("-active")
        }
    }

    let enterCallback = function() {
        $component.classList.add("-hover")
        removeActive()
        this.classList.add("-active")
    }

    let outCallback = function() {
        $component.classList.remove("-hover")
        removeActive()
    }

    for (const $item of $items) {
        $item.addEventListener("mouseover", enterCallback)
        $item.addEventListener("mouseout", outCallback)
    }
}

export default component
