import Flickity from "flickity"
import { throttle } from "@/utils/utils"
import "flickity/css/flickity.css"

const carousel = {}

carousel.init = () => {
    const $carousels = document.querySelectorAll(".-js-carousel")

    if (!$carousels) {
        return
    }

    for (const $carousel of $carousels) {
        const $items = $carousel.querySelectorAll(".-js-carousel-item")

        if ($items.length < 2) {
            continue
        }

        carousel.setHeight($items)
        $carousel.classList.add("-js-upgraded")

        const options = {
            autoPlay: 7000,
            pauseAutoPlayOnHover: false,
            pageDots: false,
            groupCells: "102%",
        }

        new Flickity($carousel, options)

        window.addEventListener(
            "resize",
            throttle(() => {
                carousel.setHeight($items)
            }, 100)
        )
    }
}

carousel.setHeight = ($items) => {
    let height = 100

    for (const $item of $items) {
        $item.style.height = ""

        if (height < $item.clientHeight) {
            height = $item.clientHeight
        }
    }

    for (const $item of $items) {
        $item.style.height = height + "px"
    }
}

export default carousel
