import "./index.styl"

import { Button, Ripple } from "@framework"
import Base from "./template/base/base.js"
import Carousel from "./component/carousel/carousel.js"
import Modal from "./component/modal/modal.js"
import PagesList from "./component/pages-list/pages-list.js"
import VideosList from "./component/videos-list/videos-list.js"
import Gallery from "./component/gallery/gallery.js"

// Init before DOMContentLoaded
Base.init()

// Init after DDOMContentLoadedOM
document.addEventListener("DOMContentLoaded", () => {
    // Vendors
    Button.init()
    Ripple.init()
    // Components
    Carousel.init()
    Gallery.init()
    Modal.init()
    PagesList.init()
    VideosList.init()
})
