import Flickity from "flickity"
import "flickity/css/flickity.css"

let videosList = {}

videosList.init = () => {
    let $links = document.querySelectorAll(".-js-video-link")
    let $modal = document.querySelector(".-js-video-list-player")

    if (!$modal) {
        return
    }

    let $closeModal = $modal.querySelectorAll(".-js-modal-close")
    let $iframe = $modal.querySelector(".-js-video-list-iframe")

    if (!$closeModal.length || !$iframe) {
        return
    }

    for (let $link of $links) {
        $link.addEventListener("click", function(e) {
            e.preventDefault()
            $modal.classList.add("-open")
            $iframe.setAttribute("src", this.getAttribute("href"))
        })
    }

    for (let $close of $closeModal) {
        $close.addEventListener("click", function(e) {
            $iframe.setAttribute("src", "")
        })
    }
}

export default videosList
