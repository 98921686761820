import "lightgallery.js"
import "lg-autoplay.js"
import "picturefill"

const gallery = {}

gallery.init = () => {
    const $gallery = document.querySelector(".c-Gallery")
    if (!$gallery) {
        return
    }

    lightGallery($gallery, {
        selector: ".c-Gallery__img",
        download: false,
    })
}

export default gallery
